<template>
  <div class="text-center">
    <h1>{{ pdfBase64.description }}</h1>
    <pdf :source="pdfDataUrl" >
      <template v-slot:loading> loading content here... </template>
    </pdf>
  </div>
</template>

<script>
import pdf from 'vue-pdf-embed'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
export default {
  components: {
    pdf
  },
  setup (props, { emit }) {
    const store = useStore()
    const pdfBase64 = computed(() => store.getters.pdftToView)
    const pdfDataUrl = computed(() => {
      return `data:application/pdf;base64,${store.getters.pdftToView.data}`
    })

    onMounted(async () => {
      // console.log('pdf>', pdf)
      // const pdfData = pdf.createLoadingTask(pdfDataUrl)
      // console.log('pdfData>', pdfData)
    })

    return {
      pdfBase64,
      pdfDataUrl
    }
  }
}
</script>

<style>
</style>
