<template>
    <div>
      <router-link to="/" class="btn btn-primary mb-3">
         Return to Shared Files
      </router-link>
      <pdf-viewer/>
    </div>
</template>

<script>
import PdfViewer from '../components/PdfViewer.vue'
export default {
  components: {
    PdfViewer
  }
}
</script>

<style>

</style>
