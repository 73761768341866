import * as types from '../mutation-types.js'

const state = {
  token: '',
  isLogin: false,
  userInfo: null,
  userLocations: [],
  selectedLocation: null,
  base64Pdf: {
    data: '',
    description: ''
  }
}

const mutations = {
  [types.IS_LOGIN] (state, payload) {
    state.isLogin = payload
  },
  [types.USER_TOKEN] (state, payload) {
    state.token = payload
  },
  [types.USER_INFO] (state, payload) {
    state.userInfo = payload
  },
  [types.USER_LOCATIONS] (state, payload) {
    state.userLocations = payload
  },
  [types.USER_SELECTED_LOCATION] (state, payload) {
    state.selectedLocation = payload
  },
  [types.BASE_64_PDF] (state, payload) {
    state.base64Pdf = payload
  }
}

export default {
  state,
  mutations
}
