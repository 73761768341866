<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="javascript:void(0)">
        <img
          class="mb-2 me-5"
          src="@/assets/logo.png"
          height="30"
          alt="Appointment App"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse text-center" id="navbarSupportedContent">
        <ul class="navbar-nav  justify-content-center me-auto mb-2 mb-lg-0">

        </ul>
        <!-- <form class="d-flex me-2 mb-1">
          <input
            class="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
        <div class="dropdown">
          <a
            href="#"
            class="d-block link-dark text-decoration-none dropdown-toggle  w-100"
            id="dropdownUser1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :class="{ show: showMenu }"
            @click="showMenu = !showMenu"
          >
            <!-- <img
                src="https://github.com/mdo.png"
                alt="mdo"
                width="32"
                height="32"
                class="rounded-circle"
              /> -->
            <span class="initial">{{ initials }}</span>
          </a>
          <ul
            class="dropdown-menu text-small"
            aria-labelledby="dropdownUser1"
            :class="{ show: showMenu }"
          >
            <!-- <li><hr class="dropdown-divider" /></li> -->
            <li>
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                @click="signOut"
                >Sign out</a
              >
            </li>
          </ul>
        </div>
        <!-- <div>
          <a class="dropdown-item"
             href="javascript:void(0)"
             @click="signOut">Sign out</a>
        </div> -->
      </div>
    </div>
  </nav>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
export default {
  components: {},
  setup () {
    const store = useStore()
    const router = useRouter()

    const userInfo = computed(() => {
      return store.getters.userInfo
    })
    const currentLocation = computed(() => store.getters.userCurrentLocation)
    const locations = computed(() => store.getters.userLocations)
    const showMenu = ref(false)
    const signOut = () => {
      localStorage.clear()
      router.push('/account/login')
    }

    const setNewLocation = async (newLocation) => {
      // Reauthenticate the user to acquire new token
      try {
        // const response = await fetch(process.env.VUE_APP_API_URL + '/users/authenticatebytoken', {
        //   method: 'POST', // *GET, POST, PUT, DELETE, etc.
        //   mode: 'cors', // no-cors, *cors, same-origin
        //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //   credentials: 'same-origin', // include, *same-origin, omit
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'char-set': 'UTF-8'
        //     // 'Content-Type': 'application/x-www-form-urlencoded',
        //   },
        //   redirect: 'follow', // manual, *follow, error
        //   referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //   body: JSON.stringify({
        //     token: userInfo.value.token
        //   }) // body data type must match "Content-Type" header
        // })

        // console.log(response.json())

        // const userResult = await axios.post(
        //   process.env.VUE_APP_API_URL + '/users/authenticatebytoken',
        //   {
        //     token: userInfo.value.token,

        //   }
        // )
        const userResult = await axios.post(
          process.env.VUE_APP_API_URL + '/users/authenticatebytoken',
          {
            username: userInfo.value.username,
            facilityKey: newLocation.key
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'bearer ' + userInfo.value.token
            }
          }
        )
        store.dispatch('setUserCurrentLocation', newLocation)
        store.dispatch('setUserToken', userResult.data.token)
        store.dispatch('setUserInfo', userResult.data)
      } catch (ex) {
        alert('Error Reauth>', ex)
      }
    }
    const initials = computed(() => {
      return (
        userInfo.value.firstName.substring(0, 1).toUpperCase() +
        userInfo.value.lastName.substring(0, 1).toUpperCase()
      )
    })

    onMounted(async () => {
      // router.go()
    })
    return {
      userInfo,
      signOut,
      currentLocation,
      locations,
      showMenu,
      initials,
      setNewLocation
    }
  }
}
</script>

<style>
.initial {
  background: #21466e;
  color: white;
  opacity: 1;
  content: attr(data-initials);
  display: inline-block;
  font-weight: 600;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
</style>
