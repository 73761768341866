import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.css'

createApp(App).use(store).use(router).mount('#app')

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token')
    if (token) {
      config.headers.common.Authorization = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          // do something
          break

        case 401:
          console.error('401 error occured, clearing session')
          sessionStorage.clear()
          router.replace({
            path: '/account/login',
            query: { redirect: router.currentRoute.fullPath }
          })
          break
        case 403:
          console.error('403 error occured, clearing session')
          sessionStorage.clear()
          router.replace({
            path: '/account/login',
            query: { redirect: router.currentRoute.fullPath }
          })
          break
        case 404:
          // alert('page not exist')
          break
        case 502:
          setTimeout(() => {
            router.replace({
              path: '/account/login',
              query: {
                redirect: router.currentRoute.fullPath
              }
            })
          }, 1000)
      }
      return Promise.reject(error.response)
    }
  }
)
