<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="fvShareModal"
      tabindex="-1"
      aria-labelledby="File Vault Modal"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <form class="modal-content" @submit.prevent="submitForm">
          <div class="modal-header">
            <h5 class="modal-title" id="fvShareModalLabel">
              {{ myModalForm.title }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>{{ myModalForm.inputLabel }}</label>
              <input
                type="text"
                class="form-control"
                v-model="myModalForm.description"
                required
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="reset"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="submit" class="btn btn-primary">Save changes</button>
          </div>
        </form>
      </div>
    </div>
    <div
      class="modal fade"
      id="fvChangeShareModal"
      tabindex="-1"
      aria-labelledby="File Vault Modal"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <form class="modal-content" @submit.prevent="changeFolder">
          <div class="modal-header">
            <h5 class="modal-title" id="fvChangeShareModalLabel">
              Move File to a different folder
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Choose folder</label>
              <select class="form-control" v-model="changeModalForm.folderId">
                <option :value="0">
                  Main folder
                </option>
                <option
                  :value="item.id"
                  v-for="item in folders"
                  :key="'chg' + item.id"
                >
                  {{ item.description }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="reset"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="submit" class="btn btn-primary">Save changes</button>
          </div>
        </form>
      </div>
    </div>
    <loading
      :active="showLoading"
      :can-cancel="false"
      :is-full-page="true"
      :background-color="loadingBg"
    >
      <slot name="default">
        <div class="text-center fa-5x">
          <i class="fas fa-spinner fa-pulse"></i></div
      ></slot>
      <slot name="after" class="after-icon">
        <span>{{ loadingText }} </span></slot
      >
    </loading>

    <image-viewer
      :image-url="imageUrl"
      :description="imageDescription"
      @closeImageViewer="closeImageViewer"
    />

    <h1>{{ currentTitle }}</h1>
    <hr />
    <div class="row">
      <div class="col-12">
        <div class="card-box">
          <transition-group name="list" tag="div" class="row">
            <div class="col-sm-2 list-item" style="margin-right:0px;" v-if="currentFolderId > 0">
              <div class="file-man-box">
                <!-- <a href="" class="file-close"
                  ><i class="fa fa-times-circle"></i
                ></a> -->
                <div
                  class="file-img-box"
                  title="View Folder"
                  @click="currentFolderId = 0"
                >
                  <img :src="getMimeTypeIcon('folder.root')" alt="icon" />
                </div>
                <div class="file-man-title">
                  <h5 class="mb-0 text-overflow" :title="'Main folder'">
                    Main folder
                  </h5>
                  <!-- <p class="m-b-0 text-overflow">
                    {{ formatDate(file.entryDate) }}
                  </p>
                  <small>
                    <span class="date text-muted">shared by:</span>
                    <br />
                    <span class="text-muted">{{ file.sharedByName }}</span>
                  </small> -->
                </div>
              </div>
            </div>
            <div
              class="col-sm-3 list-item"
              style="margin-right:0px;"
              v-for="folder in foldersToDisplay"
              :key="'folder' + folder.id"
            >
              <div class="file-man-box" v-if="currentFolderId === 0">
                <a
                  href="javascript:void(0);"
                  class="file-close"
                  @click="deleteFolder(folder.id)"
                  ><i class="fa fa-times-circle"></i
                ></a>
                <div
                  class="file-img-box"
                  title="View Folder"
                  @click="currentFolderId = folder.id"
                  @contextmenu.prevent.stop="
                    folderContextMenuClick($event, folder)
                  "
                >
                  <img :src="getMimeTypeIcon()" alt="icon" />
                </div>
                <div class="file-man-title">
                  <h5 class="mb-0 text-overflow" :title="folder.description">
                    {{ folder.description }}
                  </h5>
                  <small>
                    <span class="text-muted">{{ fileCountByFolder(folder.id) }}</span>
                  </small>
                  <div class="col-sm-12 mt-3">
                    <div
                      class="btn-group me-2"
                      role="group"
                      aria-label="First group"
                    >
                      <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="folderShowRename(folder)"
                      >
                        Rename
                        <i class="fa-solid fa-pencil"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-sm-2 list-item"
              style="margin-right:0px;"
              v-if="currentFolderId === 0"
            >
              <div class="file-man-box">
                <!-- <a href="" class="file-close"
                  ><i class="fa fa-times-circle"></i
                ></a> -->
                <div
                  class="file-img-box"
                  title="Add Folder"
                  @click="addNewFolder"
                >
                  <img :src="getMimeTypeIcon('folder.new')" alt="icon" />
                </div>
                <div class="file-man-title">
                  <h5 class="mb-0 text-overflow" :title="'Add folder'">
                    Add folder
                  </h5>
                  <!-- <p class="m-b-0 text-overflow">
                    {{ formatDate(file.entryDate) }}
                  </p>
                  <small>
                    <span class="date text-muted">shared by:</span>
                    <br />
                    <span class="text-muted">{{ file.sharedByName }}</span>
                  </small> -->
                </div>
              </div>
            </div>
            <div
              class="col-sm-3 list-item"
              style="margin-right:0px;"
              v-for="file in userSharedFilesByFolderId"
              :key="'file' + file.id"
            >
              <div class="file-man-box">
                <!-- <a href="javascript:void(0)" class="file-close"
                  ><i class="fa fa-times-circle"></i
                ></a> -->
                <div
                  class="file-img-box"
                  @click="getFile(file)"
                  title="View File"
                  @contextmenu.prevent.stop="
                    fileContextMenuClicked($event, file)
                  "
                >
                  <img :src="getMimeTypeIcon(file.storedFileName)" alt="icon" />
                </div>
                <a
                  href="javascript:void(0)"
                  class="file-download"
                  @click="downloadFile(file)"
                  ><i class="fa fa-download"></i
                ></a>
                <div class="file-man-title">
                  <h5 class="mb-0 text-overflow" :title="file.description">
                    {{ file.description }}
                  </h5>
                  <p class="m-b-0 text-overflow">
                    {{ formatDate(file.entryDate) }}
                  </p>
                  <small>
                    <span class="date text-muted">shared by:</span>
                    <br />
                    <span class="text-muted">{{ file.sharedByName }}</span>
                  </small>
                </div>
                <div class="col-sm-12 my-2">
                  <div
                    class="btn-toolbar"
                    role="toolbar"
                    aria-label="Toolbar with button groups"
                  >
                    <div
                      class="btn-group me-2"
                      role="group"
                      aria-label="First group"
                    >
                      <button
                        type="button"
                        class="btn btn-sm btn-primary"
                        @click="fileMoveToOtherFolder(file)"
                      >
                        Move
                        <i class="fa-solid fa-clone"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
          <!-- <div class="text-center mt-3">
            <button
              type="button"
              class="btn btn-outline-danger w-md waves-effect waves-light"
            >
              <i class="mdi mdi-refresh"></i> Load More Files
            </button>
          </div> -->
        </div>
      </div>
      <!-- end col -->
    </div>
  </div>
</template>

<script>
import ImageViewer from '../components/ImageViewer.vue'
import axios from 'axios'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'
import jpgIcon from '../assets/jpg.svg'
import bmpIcon from '../assets/bmp.svg'
import pdfIcon from '../assets/pdf.svg'
import pngIcon from '../assets/png.svg'
import folderIcon from '../assets/folder.svg'
import addFolder from '../assets/add-folder.svg'
// eslint-disable-next-line no-unused-vars
import rootFolderIcon from '../assets/root folder.svg'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import * as bootstrap from '../../node_modules/bootstrap/dist/js/bootstrap.esm.js'

export default {
  name: 'Home',
  components: {
    ImageViewer,
    Loading
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const userInfo = computed(() => {
      return store.getters.userInfo
    })

    const loadingBg = '#4B4B4B'
    const showLoading = ref(false)
    const loadingText = ref('')
    const userSharedFiles = ref([])
    const userSharedFilesByFolderId = computed(() =>
      userSharedFiles.value.filter(
        (el) => el.folderID === currentFolderId.value
      )
    )
    const fileToShow = ref(null)
    const imageUrl = ref('')
    const imageDescription = ref('')

    const folders = ref([])
    const foldersToDisplay = computed(() => {
      if (currentFolderId.value > 0) {
        return []
      }

      return folders.value
    })
    const currentFolderId = ref(0)
    const currentFolder = computed(() => {
      return folders.value.find((el) => el.id === currentFolderId.value)
    })
    const currentTitle = computed(() => {
      if (currentFolderId.value === 0) {
        return 'Shared Files'
      }

      return `Shared Files > ${currentFolder.value.description}`
    })
    const formatDate = (payload) => {
      return dayjs(payload).format('MMM DD, YYYY')
    }

    const closeImageViewer = async () => {
      imageUrl.value = ''
      imageDescription.value = ''
    }

    const getFile = async (payload) => {
      showLoading.value = true
      loadingText.value = 'Fetching file, this could take a while to load.'
      const fileResponse = await axios.post(
        process.env.VUE_APP_API_URL + '/common/getfilevaultsharedfile',
        {
          serverId: payload.fileVaultServerID,
          documentContainer: payload.container,
          storedFileName: payload.storedFileName
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.value.token}`
          }
        }
      )

      const arr = fileResponse.data.originalFileName.split('.')
      const mimeType = arr[arr.length - 1].toLowerCase()
      if (mimeType === 'pdf') {
        store.dispatch('setPdfToView', {
          data: fileResponse.data.base64String,
          description: payload.description
        })
        showLoading.value = false
        loadingText.value = ''
        // TODO:  Goto pdf viewing page
        router.push({ path: '/viewpdf' })
      }
      showLoading.value = false
      loadingText.value = ''
      var dataUrl = 'data:image/;base64,' + fileResponse.data.base64String
      imageUrl.value = dataUrl
      imageDescription.value = payload.description
    }

    const downloadFile = async (payload) => {
      showLoading.value = true
      loadingText.value = 'Preparing to download the file, could take a while.'
      const fileResponse = await axios.post(
        process.env.VUE_APP_API_URL + '/common/getfilevaultsharedfile',
        {
          serverId: payload.fileVaultServerID,
          documentContainer: payload.container,
          storedFileName: payload.storedFileName
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.value.token}`
          }
        }
      )

      showLoading.value = false
      loadingText.value = ''

      const file = fileResponse.data
      const a = document.createElement('a')
      a.href = 'data:image/;base64,' + file.base64String
      a.download = payload.originalFileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }

    const getMimeTypeIcon = (fileName) => {
      // icons are based on https://coderthemes.com/highdmin/layouts/assets/images/file_icons/jpg.svg
      if (!fileName) {
        return folderIcon
      }

      const arr = fileName.split('.')
      const mimeType = arr[arr.length - 1]
      switch (mimeType.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
          return jpgIcon
        case 'png':
          return pngIcon
        case 'bmp':
          return bmpIcon
        case 'pdf':
          return pdfIcon
        case 'root':
          return rootFolderIcon
        case 'new':
          return addFolder
      }
    }

    const getSfSharedFiles = async () => {
      try {
        showLoading.value = true
        loadingText.value = 'Fetching file, this could take a while to load.'
        const files = await axios.get(
          process.env.VUE_APP_API_URL +
            `/common/getfilevaultsharesharedtolist/${userInfo.value.userId}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.value.token}`
            }
          }
        )
        userSharedFiles.value = files.data
      } catch (ex) {
        console.error(ex)
        alert('Exception error occured, please try again.')
      } finally {
        showLoading.value = false
      }
    }

    const getSfShareFolders = async (userId) => {
      try {
        showLoading.value = true
        loadingText.value = 'Fetching file, this could take a while to load.'
        const fileResponse = await axios.get(
          process.env.VUE_APP_API_URL +
            `/common/getfilevaultsharefolders/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.value.token}`
            }
          }
        )

        folders.value = fileResponse.data
      } catch (ex) {
        console.error(ex)
        alert('Exception error occured, please try again.')
      } finally {
        showLoading.value = false
      }
    }

    const optionsArray1 = ref([
      {
        name: 'Duplicate',
        slug: 'duplicate'
      },
      {
        type: 'divider'
      },
      {
        name: 'Edit',
        slug: 'edit'
      },
      {
        name: '<em>Delete</em>',
        slug: 'delete'
      }
    ])
    const folderMenu = ref([
      {
        name: 'Rename'
      },
      {
        name: 'Delete'
      }
    ])
    const folderContextMenu = ref(null)
    const folderContextMenuClick = (event, item) => {
      folderContextMenu.value.showMenu(event, item)
    }
    const folderOptionClicked = (event) => {
      console.log(event)
      switch (event.option.name) {
        case 'Rename':
          myModalForm.value.title = 'Rename Folder'
          myModalForm.value.inputLabel = 'Enter new name of the folder'
          myModalForm.value.type = 'rename-folder'
          myModalForm.value.folderId = event.item.id
          myModalForm.value.description = ''
          myModal.value.toggle()
          break
        case 'Delete':
          alert('Alert to confirm delete!')
          break
      }
    }
    const folderShowRename = (folder) => {
      myModalForm.value.title = 'Rename Folder'
      myModalForm.value.inputLabel = 'Enter new name of the folder'
      myModalForm.value.type = 'rename-folder'
      myModalForm.value.folderId = folder.id
      myModalForm.value.description = ''
      myModal.value.toggle()
    }

    const fileMenu = ref([
      {
        name: 'Move file'
      }
    ])
    const fileContextMenu = ref(null)
    const fileContextMenuClicked = (event, item) => {
      fileContextMenu.value.showMenu(event, item)
    }
    const fileOptionsClicked = (event) => {
      console.log(event)
      changeModalForm.value.fileVaultShareId = event.item.id
      changeModalForm.value.folderId = event.item.folderID
      changeModal.value.toggle()
    }
    const fileMoveToOtherFolder = (file) => {
      changeModalForm.value.fileVaultShareId = file.id
      changeModalForm.value.folderId = file.folderID
      changeModal.value.toggle()
    }
    const fileCountByFolder = (folderId) => {
      const fileCount = userSharedFiles.value.filter(
        (el) => el.folderID === folderId
      )
      if (fileCount.length === 0) {
        return 'No files in folder.'
      }

      return `${fileCount.length} file(s) in folder.`
    }

    const addNewFolder = () => {
      myModalForm.value.title = 'New Folder'
      myModalForm.value.inputLabel = 'Enter name of the folder'
      myModalForm.value.type = 'new-folder'
      myModalForm.value.folderId = 0
      myModalForm.value.description = ''
      myModal.value.toggle()
    }
    const deleteFolder = async (folderId) => {
      const response = confirm('Are you sure you want to delete the folder?')
      if (!response) {
        return
      }

      await axios.post(
        process.env.VUE_APP_API_URL + '/common/deletefilevaultsharefolder',
        {
          folderId: folderId,
          userId: userInfo.value.userId
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.value.token}`
          }
        }
      )
      await getSfShareFolders(userInfo.value.userId)
    }

    const myModal = ref(null)
    const myModalForm = ref({
      title: '',
      type: '',
      inputLabel: '',
      description: '',
      folderId: 0
    })
    const submitForm = async () => {
      let apiEndpoint = ''
      let apiPayload = ''
      switch (myModalForm.value.type) {
        case 'new-folder':
          apiEndpoint =
            process.env.VUE_APP_API_URL + '/common/insertfilevaultsharefolder'
          apiPayload = {
            userId: userInfo.value.userId,
            description: myModalForm.value.description
          }
          break
        case 'rename-folder':
          apiEndpoint =
            process.env.VUE_APP_API_URL + '/common/updatefilevaultsharefolder'
          apiPayload = {
            userId: userInfo.value.userId,
            description: myModalForm.value.description,
            folderId: myModalForm.value.folderId
          }
          break
        default:
          alert('Unknown submit type!')
          return
      }

      await axios.post(apiEndpoint, apiPayload, {
        headers: {
          Authorization: `Bearer ${userInfo.value.token}`
        }
      })

      await getSfSharedFiles()

      await getSfShareFolders(userInfo.value.userId)

      myModal.value.toggle()
    }

    const changeModal = ref(null)
    const changeModalForm = ref({
      fileVaultShareId: 0,
      folderId: 0
    })
    const changeFolder = async () => {
      showLoading.value = true
      loadingText.value = 'Moving file to certain folder...'
      await axios.post(
        process.env.VUE_APP_API_URL + '/common/updatefilevaultsharefolderid',
        changeModalForm.value,
        {
          headers: {
            Authorization: `Bearer ${userInfo.value.token}`
          }
        }
      )

      setTimeout(async () => {
        await getSfSharedFiles()
      }, 1500)
      changeModal.value.toggle()
    }

    onMounted(async () => {
      const modalDom = document.getElementById('fvShareModal')
      myModal.value = new bootstrap.Modal(modalDom)
      const modalDom2 = document.getElementById('fvChangeShareModal')
      changeModal.value = new bootstrap.Modal(modalDom2)

      await getSfSharedFiles()

      if (route.params.id) {
        const result2 = await axios.get(
          process.env.VUE_APP_API_URL +
            `/common/getfilevaultshare/${route.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.value.token}`
            }
          }
        )

        fileToShow.value = result2.data

        getFile(fileToShow.value)
      }

      await getSfShareFolders(userInfo.value.userId)
    })

    return {
      userSharedFiles,
      fileToShow,
      formatDate,
      getFile,
      imageUrl,
      imageDescription,
      closeImageViewer,
      getMimeTypeIcon,
      downloadFile,
      showLoading,
      loadingText,
      loadingBg,
      folders,
      userSharedFilesByFolderId,
      currentFolderId,
      foldersToDisplay,
      folderContextMenuClick,
      folderOptionClicked,
      optionsArray1,
      folderContextMenu,
      folderMenu,
      fileMenu,
      fileContextMenu,
      fileContextMenuClicked,
      fileOptionsClicked,
      addNewFolder,
      myModalForm,
      submitForm,
      deleteFolder,
      currentFolder,
      currentTitle,
      changeModal,
      changeModalForm,
      changeFolder,
      fileMoveToOtherFolder,
      fileCountByFolder,
      folderShowRename
    }
  }
}
</script>

<style scoped>
.card-box {
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 30px;
  background-color: #fff;
}

.file-man-box {
  padding: 20px;
  border: 1px solid #e3eaef;
  border-radius: 5px;
  position: relative;
  margin-bottom: 20px;
}

.file-man-box .file-close {
  color: #f1556c;
  position: absolute;
  line-height: 24px;
  font-size: 24px;
  right: 10px;
  top: 10px;
  visibility: hidden;
}

.file-man-box .file-img-box {
  line-height: 120px;
  text-align: center;
}

.file-man-box .file-img-box img {
  height: 64px;
}

.file-man-box .file-download {
  font-size: 32px;
  color: #98a6ad;
  position: absolute;
  right: 10px;
}

.file-man-box .file-download:hover {
  color: #313a46;
}

.file-man-box .file-man-title {
  padding-right: 25px;
}

.file-man-box:hover {
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.file-man-box:hover .file-close {
  visibility: visible;
}
.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  overflow: hidden;
}
.after-icon {
  position: absolute;
  left: -80%;
  width: 200px;
}

.vue-simple-context-menu {
  background-color: #ecf0f1;
  border-bottom-width: 0px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(51, 51, 51, 0.2);
  display: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 1000000;
}
.vue-simple-context-menu--active {
  display: block;
}
.vue-simple-context-menu__item {
  align-items: center;
  color: #333;
  cursor: pointer;
  display: flex;
  padding: 5px 15px;
}
.vue-simple-context-menu__item:hover {
  background-color: #007aff;
  color: #fff;
}
.vue-simple-context-menu__divider {
  background-clip: content-box;
  background-color: #c0cdd1;
  box-sizing: content-box;
  height: 2px;
  padding: 4px 0;
  pointer-events: none;
}
.vue-simple-context-menu li:first-of-type {
  margin-top: 4px;
}
.vue-simple-context-menu li:last-of-type {
  margin-bottom: 4px;
}
</style>
