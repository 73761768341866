import { createStore } from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import VuexPersist from 'vuex-persistedstate'

// module registration
import user from './modules/user.js'

const vuexStorage = new VuexPersist({
  key: 'sfvc', // The key to store the state on in the storage provider.
  storage: window.sessionStorage,
  paths: [
    'user.token',
    'user.isLogin',
    'user.userInfo',
    'user.userLocations',
    'user.selectedLocation'
  ]
})

export default createStore({
  actions,
  getters,
  modules: {
    user
  },
  plugins: [vuexStorage],
  strict: true
})
