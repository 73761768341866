<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="showIdleNotif">
      Due to inactivity you're account will be signout. {{ countDown }}
    </div>
    <header class="p-3 mb-3 border-bottom">
      <div class="container">
        <nav-header />
      </div>
    </header>
    <div>
      <!-- <transition name="fade">
        <router-view class="container"></router-view>
      </transition> -->
      <router-view class="container-lg"></router-view>
    </div>
  </div>
</template>

<script>
import IdleJs from 'idle-js'
import NavHeader from './MainLayoutHeader.vue'
import { useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'
export default {
  components: {
    NavHeader
  },
  setup () {
    const router = useRouter()
    const showIdleNotif = ref(false)
    const countDown = ref(5)
    var idle = new IdleJs({
      idle: 1200000, // idle time in ms
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
      onIdle: async () => {
        showIdleNotif.value = true
        setInterval(() => {
          countDown.value -= 1
          if (countDown.value === 0) {
            localStorage.clear()
            router.push({ path: '/account/login' })
          }
        }, 1000)
      }, // callback function to be executed after idle time
      onActive: () => {}, // callback function to be executed after back form idleness
      onHide: () => {}, // callback function to be executed when window become hidden
      onShow: () => {}, // callback function to be executed when window become visible
      keepTracking: false, // set it to false if you want to be notified only on the first idleness change
      startAtIdle: false // set it to true if you want to start in the idle state
    })

    onMounted(async () => {
      // console.log('Tracking idle ...')
      idle.start()
    })

    return {
      showIdleNotif,
      countDown
    }
  }
}
</script>
