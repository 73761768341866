// eslint-disable-next-line no-unused-vars
import {
  createRouter,
  // createWebHistory,
  createWebHashHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import ViewPdf from '../views/ViewPdf.vue'
import MainLayout from '../layouts/MainLayout.vue'
import LoginLayout from '../layouts/LoginLayout.vue'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: MainLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: ':id',
        name: 'Home2',
        component: Home
      },
      {
        path: 'viewpdf',
        name: 'ViewPdf',
        component: ViewPdf
      }
    ]
  },
  {
    path: '/account',
    name: 'Account',
    component: LoginLayout,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/Login.vue')
      },
      {
        path: 'pwdreset',
        name: 'PasswordReset',
        component: () => import('../views/PasswordReset.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// Setup router guard
router.beforeResolve((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    const isAuth = sessionStorage.getItem('sfvc')
    // console.log('isAuth>', isAuth)
    // console.log('to>', to)

    if (!isAuth) {
      if (to.params.id) {
        next({
          path: '/account/login',
          query: {
            id: to.params.id
          }
        })
      } else {
        next({ path: '/account/login' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
