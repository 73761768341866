import * as types from './mutation-types.js'

export const setUserIsLogin = (store, payload) => {
  store.commit(types.IS_LOGIN, payload)
}

export const setUserToken = (store, payload) => {
  store.commit(types.USER_TOKEN, payload)
}

export const setUserInfo = (store, payload) => {
  store.commit(types.USER_INFO, payload)
}

export const setUserLocations = (store, payload) => {
  store.commit(types.USER_LOCATIONS, payload)
}

export const setUserCurrentLocation = (store, payload) => [
  store.commit(types.USER_SELECTED_LOCATION, payload)
]

export const setPdfToView = (store, payload) => {
  store.commit(types.BASE_64_PDF, payload)
}
